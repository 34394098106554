import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Grid, Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Paper, LinearProgress, CircularProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useAuthentication_encad from './useAuthentication_encad';
import Header from './Header';
import Footer from "./Footer";
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import axios from 'axios';
import '../containers/responsivetable.css'; // Import the font CSS file

function InfoEncadr() {
  const { IDEncadrement, encadata, loadingauth } = useAuthentication_encad();

  const [EndTimeupdatedb, setEndTimeupdatedb] = useState("");

  const apiUrl_lastupdatedb = `${API_BASE_URL}/api/lastupdatedb/Encadrement`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_lastupdatedb);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data) {
          throw new Error("Unexpected data format received from API");
        }

        // Process fetched data
        const lastupdatedb = data.data;

        // Set selected session if available
        if (lastupdatedb && lastupdatedb.EndTime) {
          setEndTimeupdatedb(lastupdatedb.EndTime);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);

  // Function to format date in Arabic with Arabic numerals
  const formatArabicDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, localeMatcher: 'best fit', weekday: "long", era: "long" };
    const easternArabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const arabicNumerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const formattedDate = new Date(dateString).toLocaleString('ar-EG', options);
    // Replace Eastern Arabic numerals with Arabic numerals
    return formattedDate.replace(/[٠-٩]/g, (match) => arabicNumerals[easternArabicNumerals.indexOf(match)]);
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));


  // Function to handle file download
  const downloadfromlaravel = async () => {
    try {
      const response = await axios({

        url: `${API_BASE_URL}/api/downloadcvencadr`, // Replace with your Laravel endpoint
        method: 'GET',
        responseType: 'blob', // Important to handle binary data
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      });

      // Create a URL for the file and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cv.pdf'); // Replace 'cv.pdf' with the desired file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('فشل تحميل الملف. يرجى التأكد من رفع ملف السيرة الذاتية');

    }
  };


  useEffect(() => {
    if (!loadingauth && IDEncadrement === null) {
      // Navigate to the 404 page if any value is missing
      // navigate('/');
      return;
    }
  }, [IDEncadrement, loadingauth, encadata, navigate]);

  if (!encadata) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  /* 
  
  EndicapePourcentage.Nom AS EndicapePourcentageNom,
  Endicapetype.Nom AS EndicapetypeNom,
  Encadrement.IDEndicapePourcentage,
  Encadrement.IDEndicapetype,
  Encadrement.endicape,

  */
  const rows = [
    {
      label: "ذوي الاحتياجات الخاصة", value: encadata?.endicape === 0 ? "لا" :
        encadata?.endicape === 1 ? "نعم" + " (" + encadata?.EndicapePourcentageNom + " - " + encadata?.EndicapetypeNom + ") " : ''
    },
    { label: "الجنس", value: encadata?.Civ === 1 ? "ذكر" : encadata?.Civ === 2 ? "أنثى" : '' },
    { label: "تاريخ الميلاد", value: encadata?.DateNais ?? '' },
    { label: "المكان", value: encadata?.LieuNais ?? '' },
    { label: "الوضعية الإدارية", value: encadata?.SituationAdministratNom ?? '' },
    { label: "المستوى الدراسي", value: encadata?.Niveau_Scol_EncaNom ?? '' },
    { label: "الشهادة", value: encadata?.DiplomeNom ?? '' },
    { label: "الاختصاص", value: encadata?.Specialite ?? '' },
    { label: "الشعبة", value: encadata?.BrancheNom ?? '' },
    { label: "الرتبة الحالية", value: encadata?.GradeNom ?? '' },
    { label: "تاريخ التوظيف", value: encadata?.Daterecr ?? '' },
    { label: "تاريخ التثبيت", value: encadata?.DateInstall ?? '' },
    { label: "الوظيفة / المنصب العالي", value: encadata?.FonctionsNom ?? '' },
    { label: "الرتبة الأصلية", value: encadata?.CorpNom ?? '' },
    { label: "التوظيف", value: encadata?.daterecrPoste ?? '' },
    { label: "التثبيت", value: encadata?.DateinstallPoste ?? '' },
    { label: "الهاتف", value: encadata?.Tel ?? '' },
    { label: "العنوان", value: encadata?.Adres ?? '' },
    { label: "رقم عقد الميلاد", value: encadata?.numActNaiss ?? '' },
    { label: "رقم التعريف الوطني", value: encadata?.nin ?? '' },
    { label: "رقم الضمان الإجتماعي", value: encadata?.nss ?? '' },
    { label: "الدرجة", value: encadata?.Echlo ?? '' },
    { label: "تاريخ الدرجة", value: encadata?.DateEchlon ?? '' }
  ];

  return (
    <>
      <Header />
      <Box sx={{ mt: 0, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!encadata && <CircularProgress />}
      </Box>

      <Grid item sx={{ width: "100%" }}>
        <Divider sx={{ mt: "5px" }}>
          <Grid item>
            <Typography
              style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 25,
                fontWeight: 700,
                marginBottom: "25px",
              }}
              variant="h3"
              gutterBottom
            >
              <PersonIcon sx={{ mr: "5px" }} />
              ملف المستخدم
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontFamily: '"Tajawal", sans-serif',
                color: "#004d40",
                fontSize: 12,
                fontWeight: 'bold',
                mt: 1,
              }}
            >
              {EndTimeupdatedb && (
                <>
                  آخر تحديث: {formatArabicDate(EndTimeupdatedb)}

                </>
              )}
            </Typography>

          </Grid>
        </Divider>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          width: '90%',
          maxWidth: '1200px',
          margin: '0 auto',
          overflowX: 'auto',
        }}
      >
        {/*
    Etablissement.Nom AS EtablissementNom ,
	Etablissement.`Code` AS EtablissementCode ,
	Etablissement.IDCommunn,
  Communn.Nom AS CommunnNom,
    */}


<div className="responsive-table">

<Table  >
  <TableBody>
    <TableRow key={1}>

      <TableCell
        sx={{
          width: '200px', // Fixed width
          textAlign: 'center', // Center the image horizontally
          verticalAlign: 'middle', // Center the image vertically
          padding: 1, // Add some padding
          borderRight: '1px solid #a5d6a7', // Optional: Add border for better visual separation
        }}
      >
        <img
          src={`https://tachbik.mfp.gov.dz/api/getencadrementmemo/${encadata?.crypt_IDEncadrement}.jpg`} // Image source
          alt=""
          style={{
            width: '100%', // Make image responsive within the cell
            height: 'auto', // Maintain aspect ratio
            borderRadius: '10px', // Optional: Add border radius for styling
            objectFit: 'cover', // Ensures the image covers the area without distorting
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Optional: Add shadow for depth
          }}
        />
      </TableCell>

      {/* Second column with full remaining width */}
      <TableCell
        sx={{
          textAlign: 'right', // Align text to the right
          padding: 1, // Add padding for the content
          width: '85%', // Full width
        }}
      >

        <Table>
          <TableBody>


            <TableRow key={100}>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderRight: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                }}
              >
                البطاقة المهنية
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(`/cardencadr/${encadata?.crypt_IDEncadrement}`)}  // Correct usage

                  sx={{
                    backgroundColor: '#00695c', // Green color
                    '&:hover': {
                      backgroundColor: '#004d40', // Darker green on hover
                    },
                    padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
                    gap: '8px',  // Space between Typography and the icon

                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 20,
                    fontWeight: 700,
                  }}
                >
                  تحميل
                </Button>
              </TableCell>
            </TableRow>

            <TableRow key={1}>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderRight: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',

                }}
              >
                اللقب
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >
                {encadata?.Nom ?? ''}
              </TableCell>
            </TableRow>

            <TableRow key={2}>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderRight: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',

                }}
              >
                الإسم
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >
                {encadata?.Prenom ?? ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/* Additional candidate details can go here */}
      </TableCell>
    </TableRow>
  </TableBody>
</Table>
</div>

<div className="mobile-table">

<Table >
  <TableBody>
    <TableRow key={10}>
      <TableCell
        sx={{
          width: 'auto', // Width can be set to auto
          textAlign: 'center', // Center the image horizontally
          verticalAlign: 'middle', // Center the image vertically
          padding: 1, // Add some padding
          borderRight: '1px solid #a5d6a7', // Optional: Add border for better visual separation
        }}
      >
        <div
          style={{
            display: 'flex', // Use flexbox for centering
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            height: '100%', // Ensure it takes full height of the cell
          }}
        >
          <img
            src={`https://tachbik.mfp.gov.dz/api/getencadrementmemo/${encadata?.crypt_IDEncadrement}.jpg`} // Image source
            alt=""
            style={{
              width: '100%', // Make image responsive within the cell
              height: 'auto', // Maintain aspect ratio
              borderRadius: '10px', // Optional: Add border radius for styling
              objectFit: 'cover', // Ensures the image covers the area without distorting
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Optional: Add shadow for depth
            }}
          />
        </div>
      </TableCell>
    </TableRow>
  </TableBody>
</Table>

<Table >
  <TableBody>
    <TableRow key={100}>
      <TableCell
        sx={{
          textAlign: 'right',
          fontFamily: 'Cairo',
          fontSize: '16px',
          fontWeight: 'bold',
          backgroundColor: '#004d40',
          borderRight: '1px solid #a5d6a7',
          color: '#FFFFFF',
          whiteSpace: 'nowrap',
        }}
      >
        البطاقة المهنية
      </TableCell>
      <TableCell
        sx={{
          textAlign: 'right',
          fontFamily: 'Cairo',
          fontSize: '16px',
          fontWeight: 'bold',
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/cardencadr/${encadata?.crypt_IDEncadrement}`)}  // Correct usage

          sx={{
            backgroundColor: '#00695c', // Green color
            '&:hover': {
              backgroundColor: '#004d40', // Darker green on hover
            },
            padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
            gap: '8px',  // Space between Typography and the icon

            fontFamily: '"Tajawal", sans-serif',
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          تحميل
        </Button>
      </TableCell>
    </TableRow>

    <TableRow key={1}>
      <TableCell
        sx={{
          textAlign: 'right',
          fontFamily: 'Cairo',
          fontSize: '16px',
          fontWeight: 'bold',
          backgroundColor: '#004d40',
          borderRight: '1px solid #a5d6a7',
          color: '#FFFFFF',
          whiteSpace: 'nowrap',

        }}
      >
        اللقب
      </TableCell>
      <TableCell
        sx={{
          textAlign: 'right',
          fontFamily: 'Cairo',
          fontSize: '16px',
          fontWeight: 'bold',
          width: '100%',
        }}
      >
        {encadata?.Nom ?? ''}
      </TableCell>
    </TableRow>

    <TableRow key={2}>
      <TableCell
        sx={{
          textAlign: 'right',
          fontFamily: 'Cairo',
          fontSize: '16px',
          fontWeight: 'bold',
          backgroundColor: '#004d40',
          borderRight: '1px solid #a5d6a7',
          color: '#FFFFFF',
          whiteSpace: 'nowrap',

        }}
      >
        الإسم
      </TableCell>
      <TableCell
        sx={{
          textAlign: 'right',
          fontFamily: 'Cairo',
          fontSize: '16px',
          fontWeight: 'bold',
          width: '100%',
        }}
      >
        {encadata?.Prenom ?? ''}
      </TableCell>
    </TableRow>
  </TableBody>
</Table>
</div>



        <Table>
          <TableBody>
            <TableRow key={3}>
              <TableCell
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >
                {encadata?.NomFr ?? ''}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderLeft: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                }}
              >
                Nom
              </TableCell>
            </TableRow>

            <TableRow key={4}>
              <TableCell
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >
                {encadata?.PrenomFr ?? ''}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderLeft: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                }}
              >
                Prénom
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>


        <Table>
          <TableBody>

            <TableRow key={101}>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderRight: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                }}
              >
                المؤسسة
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >

                {`${encadata?.EtablissementCode ?? ''} | ${encadata?.EtablissementNom ?? ''} | ${encadata?.CommunnNom ?? ''}`}


              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table>
          <TableBody>

            <TableRow key={100}>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  backgroundColor: '#004d40',
                  borderRight: '1px solid #a5d6a7',
                  color: '#FFFFFF',
                  whiteSpace: 'nowrap',
                }}
              >
                السيرة الذاتية
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Cairo',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadfromlaravel}
                  sx={{
                    backgroundColor: '#00695c', // Green color
                    '&:hover': {
                      backgroundColor: '#004d40', // Darker green on hover
                    },
                    padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
                    gap: '8px',  // Space between Typography and the icon

                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 20,
                    fontWeight: 700,
                  }}
                >
                  تحميل
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Cairo',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    backgroundColor: '#004d40',
                    borderRight: '1px solid #a5d6a7',
                    color: '#FFFFFF',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.label}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'right',
                    fontFamily: 'Cairo',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                >
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        
      </TableContainer>
      <br />
      <Footer />
    </>
  );
}

export default InfoEncadr;

import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CacheProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';
import createCache from "@emotion/cache";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import useAuthentication_cand from './useAuthentication_cand';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

import getCsrfToken from '../CsrfToken';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import ReplayIcon from '@mui/icons-material/Replay';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import CardContent from '@mui/material/CardContent';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

// Set the CSRF token globally
const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

let sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
});



const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function NotesApr() {

  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    crypted_id,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_cand();

  const [loading, setLoading] = useState(true);


  const [alertMessageEdit, setAlertMessageEdit] = useState(null);


  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [OpenDialog, setOpenDialog] = useState(false);


  const [errorget, setErrorget] = useState();
  const navigate = useNavigate();

  const refDateNais = useRef(null);


  const [Civ, setCiv] = React.useState(0);

  const handleChange_Civ = (event) => {
    setCiv(event.target.value);
  };


  const handlebtn_relevenote = () => () => {

    window.open(`/Releve2notes/${crypt_IDApprenant_Section_semstre}`, "_blank");

  }


  let numrun = 0;

  useEffect(() => {



    const fetchData = async () => {


      try {
        if (!crypted_id || Number(numrun) >= 1) {

          return;
        }

        const response = await fetch(`${API_BASE_URL}/api/getbycrypted_id/${crypted_id}`
          , {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        //alert(data.data.NomPmois);

        if (Number(data.data.Eval_notes_active) === 0
          || Number(data.data_dfep.Eval_notes_active_dfep) === 0
          || Number(data.data_mfep.Eval_notes_active_mfep) === 0) {
          navigate("/");
        }

        setValuesFromData_getinfo(data.data);
        setresultdata(data.data);

      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      } finally {
        setLoading(false);
        numrun++;
      }


    };

    if (crypted_id) {
      fetchData();
    } else {

      return;
    }
  }, [crypted_id, navigate]);





  const [Nom_Et_query, setNom_Et_query] = useState();
  const [Nom_Ca_query, setNom_Ca_query] = useState();
  const [Prenom_query, setPrenom_query] = useState();
  const [DateNais_query, setDateNais_query] = useState();
  const [NumIns_query, setNumIns_query] = useState();
  const [LieuNais_query, setLieuNais_query] = useState();
  const [Adres_query, setAdres_query] = useState();
  const [Nom_query, setNom_query] = useState();
  const [Mode_formation_Nom_query, setMode_formation_Nom_query] = useState();
  const [DateDF_query, setDateDF_query] = useState();
  const [DateFF_query, setDateFF_query] = useState();
  const [NumSem_query, setNumSem_query] = useState();
  const [DateD_sem_query, setDateD_sem_query] = useState();
  const [DateF_sem_query, setDateF_sem_query] = useState();
  const [libelleArabe_Wly_Dfep_query, setlibelleArabe_Wly_Dfep_query] = useState();



  const [TotalMoyAvr, setTotalMoyAvr] = useState();
  const [resultdata, setresultdata] = useState();

  const [crypt_IDApprenant_Section_semstre, setcrypt_IDApprenant_Section_semstre] = useState();


  const [TotalMoyApr, setTotalMoyApr] = useState();
  const [NoteSoutenance, setNoteSoutenance] = useState();
  const [NoteMemoire, setNoteMemoire] = useState();
  const [MoyAvr, setMoyAvr] = useState();
  const [MoyApr, setMoyApr] = useState();
  const [MoyC1, setMoyC1] = useState();
  const [MoyC2, setMoyC2] = useState();
  const [MoyCs, setMoyCs] = useState();
  const [MoyCr, setMoyCr] = useState();
  const [Obs, setObs] = useState();
  const [NoteStage, setNoteStage] = useState();
  const [Dernier, setDernier] = useState();





  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  function setValuesFromData_getinfo(data) {
    // Check if data object is defined
    if (!data) {
      console.error('Data is undefined');
      return;
    }

    //alert(data.crypt_IDApprenant_Section_semstre);
    setcrypt_IDApprenant_Section_semstre(data.crypt_IDApprenant_Section_semstre || '');
    setTotalMoyAvr(data.TotalMoyAvr || '');
    setTotalMoyApr(data.TotalMoyApr || '');
    setNoteSoutenance(data.NoteSoutenance || '');
    setNoteMemoire(data.NoteMemoire || '');
    setMoyAvr(data.MoyAvr || '');
    setMoyApr(data.MoyApr || '');
    setMoyC1(data.MoyC1 || '');
    setMoyC2(data.MoyC2 || '');
    setMoyCs(data.MoyCs || '');
    setMoyCr(data.MoyCr || '');
    setObs(data.Obs || '');
    setNoteStage(data.NoteStage || '');



    setNom_Et_query(data.Nom_Et || '');
    setNom_Ca_query(data.Nom_Ca || '');
    setPrenom_query(data.Prenom || '');
    setDateNais_query(dayjs(data.DateNais).format('DD-MM-YYYY') || '');
    setLieuNais_query(data.LieuNais || '');
    setAdres_query(data.Adres || '');
    setNom_query(data.Nom || '');
    setMode_formation_Nom_query(data.Mode_formation_Nom || '');
    setDateDF_query(dayjs(data.DateDF).format('DD-MM-YYYY') || '');
    setDateFF_query(dayjs(data.DateFF).format('DD-MM-YYYY') || '');
    setNumSem_query(data.NumSem || '');
    setDateD_sem_query(dayjs(data.DateD_sem).format('DD-MM-YYYY') || '');
    setDateF_sem_query(dayjs(data.DateF_sem).format('DD-MM-YYYY') || '');
    setNumIns_query(data.NumIns || '');
    setlibelleArabe_Wly_Dfep_query(data.libelleArabe_Wly_Dfep || '');
    setCiv(data.Civ || '');
    setDernier(data.Dernier)
  }



  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    //// navigate('/Doc');
  };


  const [emailverificated, setemailverificated] = useState(false);

  const [emailsended, setemailsended] = useState(false);
  const [errorsendingemail, seterrorsendingemail] = useState(false);
  const [codeemailsent, setcodeemailsent] = useState(false);
  const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));

  const [valueNomMere, setvalueNomMere] = useState('');
  const [valueNomMereFr, setvalueNomMereFr] = useState('');
  const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
  const [valuePrenomMere, setvaluePrenomMere] = useState('');


  const [valueNom, setvalueNom] = useState('');
  const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
  const [valueLieuNais, setvalueLieuNais] = useState('');

  const [valueAdres, setvalueAdres] = useState('');

  const [valuePrenomPere, setvaluePrenomPere] = useState('');
  const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


  const [valueAdresFr, setvalueAdresFr] = useState('');


  const refLieuNais = useRef(null);
  const refLieuNaisFr = useRef(null);

  const refNomMere = useRef(null);
  const refNomMereFr = useRef(null);
  const refPrenomMere = useRef(null);
  const refPrenomMereFr = useRef(null);



  const handleInput_LieuNais = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueLieuNais(inputValue);
    }
  };

  const handleInput_LieuNaisFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueLieuNaisFr(inputValue);
    }
  };
  const refNom = useRef(null);
  const refvalueDateNais = useRef(null);
  const [valuePrenom, setvaluePrenom] = useState('');
  const refPrenom = useRef(null);
  const refPrenomPere = useRef(null);
  const refPrenomPereFr = useRef(null);


  const [valuenbcodeverifie, setvaluenbcodeverifie] = useState('');
  const refnbcodeverifie = useRef(null);

  const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
  const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
  const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;

  const NumbersOnlyRegex = /^[0-9]+$/;


  const handleInput_Adres = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdres(inputValue);
    }
  };

  const handleInput_PrenomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMere(inputValue);
    }
  };

  const handleInput_NomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMere(inputValue);
    }
  };

  const handleInput_Nom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNom(inputValue);
    }
  };



  const [valueDateNais, setvalueDateNais] = useState('');

  // Regular expression to match the format "YYYY/MM/DD"
  const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
  const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  const handleInput_DateNais = (event) => {
    const inputValue = event.target.value;
    setvalueDateNais(inputValue);
  };



  const handleInput_PrenomPere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPere(inputValue);
    }
  };

  const handleInput_Prenom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenom(inputValue);
    }
  };

  const handleInput_nbcodeverifie = (event) => {
    const inputValue = event.target.value;

    if (NumbersOnlyRegex.test(inputValue) || inputValue === '') {
      setvaluenbcodeverifie(inputValue);
    }
  };

  const [valueNomFr, setvalueNomFr] = useState('');
  const refNomFr = useRef(null);
  const [error, setError] = useState('');

  const [valuePrenomFr, setvaluePrenomFr] = useState('');
  const refPrenomFr = useRef(null);

  const LatinLettersRegex = /^[a-zA-Z\s]+$/;
  const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
  const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;

  const handleInput_AdresFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdresFr(inputValue);
    }
  };



  const handleInput_PrenomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMereFr(inputValue);
    }
  };

  const handleInput_NomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMereFr(inputValue);
    }
  };

  const handleInput_NomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomFr(inputValue);
    }
  };

  const handleInput_PrenomPereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPereFr(inputValue);
    }
  };

  const handleInput_PrenomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomFr(inputValue);
    }
  };



  const refbtnsubmit = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);
  const refpasswordnow = useRef(null);
  const refAdres = useRef(null);
  const refAdresFr = useRef(null);



  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });

  const [valuespasswordnow, setValuespasswordnow] = React.useState({
    amount: '',
    passwordnow: '',
    weight: '',
    weightRange: '',
    showpasswordnow: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleChangepasswordnow = (prop) => (event) => {
    setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleClickShowpasswordnow = () => {
    setValuespasswordnow({
      ...valuespasswordnow,
      showpasswordnow: !valuespasswordnow.showpasswordnow,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };

  const handleMouseDownpasswordnow = (event) => {
    event.preventDefault();
  };


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  // Store token in state
  const [csrfToken, setCsrfToken] = useState(null);

  // Fetch on app load
  useEffect(() => {
    const getCsrfToken = async () => {
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);
    };

    getCsrfToken();
  }, []);






  // Notes style
  const noteStyle_mob = {
    display: 'block',
    whiteSpace: 'normal',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: '"Tajawal", sans-serif',
    direction: 'rtl', // Set direction to right-to-left
    textAlign: 'right', // Align text to right
  };

  // Notes style
  const noteStyle = {
    display: 'block',
    whiteSpace: 'normal',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: '"Tajawal", sans-serif',
    direction: 'rtl', // Set direction to right-to-left
    textAlign: 'right', // Align text to right
  };



  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDCandidat === null
        || Number(Eval_notes_active) !== 1
        || Number(Eval_notes_active_Dfep) !== 1
        || Number(Eval_notes_active_Mfep) !== 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }


    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, crypted_id, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }


  return (
    <>



      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        crypted_id={crypted_id}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />


      <div id="root_img">

        <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loading && <CircularProgress />}
        </Box>



        {/* {!isMatch ? (   <></>):(<></>)};*/}










        <Container sx={{ textAlign: 'center', mx: 'auto', width: '80%' }}>

          <Card sx={{
            backgroundColor: '#ffffe00d',
            borderColor: '#0000ff29',
            borderStyle: 'solid',
            borderWidth: '1px',
          }}>

            <CardHeader
              sx={{
                ///backgroundImage:`url('5153829.jpg')`,
                backgroundColor: '#004d40',
                border: '2px solid #004d40',
                padding: '0px'

              }}
              title={
                <>
                  <Typography
                    style={{
                      color: "#FFFFFF",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 25,
                      fontWeight: 700,
                      display: 'block',
                      whiteSpace: 'normal',
                    }}
                  >
                    نتائج تقييم السداسي ({resultdata && resultdata.NomPmois ? resultdata.NomPmois.trim() :''})

                  </Typography>


                </>
              }
            />

            <CardContent>



              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={noteStyle}
                  >
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                      ولاية: &nbsp;
                    </span> {libelleArabe_Wly_Dfep_query} &nbsp;
                  </Typography>

                  <Typography
                    variant="body1"
                    style={noteStyle}
                  >
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                      المؤسسة:
                    </span>&nbsp;{Nom_Et_query}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container alignItems="center">

                <Grid item xs={12}>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}> اللقب و الاسم: </span> &nbsp; {Nom_Ca_query} {Prenom_query}
                  </Typography>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}> تاريخ و مكان الميلاد: </span> &nbsp; {DateNais_query} {LieuNais_query}
                  </Typography>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}> مسجل (ة) تحت رقم: </span> &nbsp; {NumIns_query}
                  </Typography>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>  الإختصاص: </span> &nbsp; {Nom_query}
                  </Typography>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>  نمط التكوين: </span> &nbsp; {Mode_formation_Nom_query}
                  </Typography>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>  مدة التكوين من: </span> &nbsp; {DateDF_query} &nbsp; <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>إلى</span> &nbsp; {DateFF_query}&nbsp;
                  </Typography>

                  <Typography variant="body1" style={noteStyle}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>  السداسي رقم: </span> &nbsp; {NumSem_query} &nbsp; <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>من</span> &nbsp; {DateD_sem_query} &nbsp; <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>إلى</span> &nbsp; {DateF_sem_query}&nbsp;
                  </Typography>


                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>


                    <div style={{
                      backgroundColor: "#004d40", // Set background color to red
                      border: "2px solid #80cbc4", // Add white border
                      padding: '10px',
                      marginBottom: '10px',
                      marginTop: '30px',
                      borderRadius: '11px',

                    }}>


                      <Typography variant="body1" style={noteStyle}>
                        <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> معدل السداسي (دورة عادية): </span> &nbsp;  <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> {isNaN(parseFloat(MoyAvr)) ? '0.00' : parseFloat(MoyAvr).toFixed(2)}  </span> &nbsp; {/*  */}
                      </Typography>

                      <Typography variant="body1" style={noteStyle}>
                        <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> معدل السداسي (بعد الإستدراك): </span> &nbsp; <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> {isNaN(parseFloat(MoyApr)) ? '0.00' : parseFloat(MoyApr).toFixed(2)}  </span> &nbsp; {/*  */}
                      </Typography>

                      {((Number(Dernier) === 1 || Dernier == "1") && (NumSem_query !== 5 || NumSem_query !== "5")) ? (<>
                        <Typography variant="body1" style={noteStyle}>
                          <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> علامة التربص: </span> &nbsp; <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}>  {isNaN(parseFloat(NoteStage)) ? '0.00' : parseFloat(NoteStage).toFixed(2)} </span> &nbsp; {/*  */}
                        </Typography>
                      </>) : null}
                      {((Number(Dernier) === 1 || Dernier == "1") && (NumSem_query === 5 || NumSem_query == "5")) ? (<>
                        <Typography variant="body1" style={noteStyle}>
                          <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> علامة المذكرة: </span> &nbsp; <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> {isNaN(parseFloat(NoteMemoire)) ? '0.00' : parseFloat(NoteMemoire).toFixed(2)}  </span> &nbsp; {/*  */}
                        </Typography>
                      </>) : null}
                      {((Number(Dernier) === 1 || Dernier == "1") && (NumSem_query === 5 || NumSem_query == "5")) ? (<>
                        <Typography variant="body1" style={noteStyle}>
                          <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> علامة المناقشة: </span> &nbsp; <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}> {isNaN(parseFloat(NoteSoutenance)) ? '0.00' : parseFloat(NoteSoutenance).toFixed(2)}  </span> &nbsp; {/*  */}
                        </Typography>

                      </>) : null}


                    </div>
                  </div>


                  <Button
                    onClick={handlebtn_relevenote()}
                    variant="contained"
                    endIcon={
                      <PictureAsPdfIcon
                        sx={{
                          ml: "8px",  // Adjust the margin to the left of the icon
                          transform: "rotate(0deg)"
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: '#00695c', // Green color
                      '&:hover': {
                        backgroundColor: '#004d40', // Darker green on hover
                      },
                      padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
                      gap: '8px',  // Space between Typography and the icon
                      mb: 5
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 12,
                        fontWeight: 900,
                      }}
                    >
                      تحميل كشف النقاط
                    </Typography>
                  </Button>

                </Grid>
              </Grid>


            </CardContent>
          </Card>

        </Container>

      </div>


      <Footer />

    </>

  );
}

export default NotesApr;

import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Select from "@mui/material/Select";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@mui/material/LinearProgress";
import useAuthentication_etab from "./useAuthentication_etab";
import GroupIcon from '@mui/icons-material/Group';
import axios from 'axios';
import LinkIcon from '@mui/icons-material/Link';
import MyDialog from './MyDialog'; // Import your Dialog component
import TextSnippetIcon from '@mui/icons-material/TextSnippet'; // Use an icon that fits
import InventoryIcon from '@mui/icons-material/Inventory';
import ConstructionIcon from '@mui/icons-material/Construction';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";
import MenuItem from "@mui/material/MenuItem";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import XLSX from "sheetjs-style";
import Footer from "./Footer";
import Header from "./Header";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import createCache from "@emotion/cache";

import "../containers/amirifont.css"; // Import the font CSS file
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@material-ui/core/styles";
import './MyToolbarStyles.css'; // Adjust the path as necessary
import useAuthentication_logged from './useAuthentication_logged';




const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  palette: {
    primary: {
      main: defaultColor
    },
    secondary: {
      main: defaultColor
    }
  },
  direction: 'rtl',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          "&:hover": {
            color: hoverColor,
          },
          color: defaultColor, // Default label color
        },
      },
    },
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  },
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1
      })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {
  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(
        isValid ? null : "Username must contain only alphabets (a to z)."
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : "Username must contain only Arabic alphabets.");
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}

const apiUrl = `${API_BASE_URL}/api/getequipment`;
const apiUrletab = `${API_BASE_URL}/api/getequipment`;
const apiUrl_getSemestre_Formation = `${API_BASE_URL}/api/getSemestre_Formation`;

function CustomToolbar({
  setRows,
  setRowModesModel,
  handleExportExcel,
  columns,
}) {
  return (
    <GridToolbarContainer className="custom-toolbar">
      <GridToolbarColumnsButton
        className="custom-toolbar"
      />
      <GridToolbarFilterButton
        className="custom-toolbar"
      />
      <GridToolbarDensitySelector
        className="custom-toolbar"
      />
      <Button
        className="custom-toolbar"
        onClick={handleExportExcel}
        color="primary"
      >
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport className="custom-toolbar" />

      <GridToolbarQuickFilter
        className="custom-toolbar"

      />
    </GridToolbarContainer>
  );
}

let options_Wly = [];
let Wlylists = [];

// Function to normalize URL input
const normalizeUrls = (value) => {
  if (typeof value === 'string') {
    // Split by comma and remove any extra spaces
    return value.split(',').map(url => url.trim()).filter(url => url);
  }
  return Array.isArray(value) ? value : [];
};

function ShowAllEquipmfep() {

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({ prenom: '', nom: '', urls: [], text: '' });

  const handleDialogOpen = (prenom, nom, urls, text) => {
    setDialogData({ prenom, nom, urls, text });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [fullDescription, setFullDescription] = React.useState('');
  const [nom, setNom] = React.useState('');

  const handleOpen = (description, nom) => {
    setFullDescription(description);
    setNom(nom);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [EndTimeupdatedb, setEndTimeupdatedb] = useState("");

  const apiUrl_lastupdatedb = `${API_BASE_URL}/api/lastupdatedb/Equipement`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_lastupdatedb);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data) {
          throw new Error("Unexpected data format received from API");
        }

        // Process fetched data
        const lastupdatedb = data.data;

        // Set selected session if available
        if (lastupdatedb && lastupdatedb.EndTime) {
          setEndTimeupdatedb(lastupdatedb.EndTime);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);



  // Function to format date in Arabic with Arabic numerals
  const formatArabicDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, localeMatcher: 'best fit', weekday: "long", era: "long" };
    const easternArabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const arabicNumerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const formattedDate = new Date(dateString).toLocaleString('ar-EG', options);
    // Replace Eastern Arabic numerals with Arabic numerals
    return formattedDate.replace(/[٠-٩]/g, (match) => arabicNumerals[easternArabicNumerals.indexOf(match)]);
  };



  const authlogged = useAuthentication_logged(); // Get values from useAuthentication_logged
  const authCandData = useAuthentication_etab(); // Always call the hook at the top level, but we will conditionally use its data
  const [authCand, setAuthCand] = useState(null); // Create a state for authCand

  // Function to get the value from authlogged
  const getValue = (key) => {
    try {
      return authlogged?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  const IDCandidat = getValue("IDCandidat");
  const IDEncadrement = getValue("IDEncadrement");
  const IDetablissement = getValue("IDetablissement");
  const IDDFEP = getValue("IDDFEP");
  const IDNature_etsF = getValue("IDNature_etsF");
  const IDUtilisateur = getValue("IDUtilisateur");
  const UtilisateurNom = getValue("UtilisateurNom");
  const LoginTracesNom = getValue("LoginTracesNom");
  const IDapis_userslist = getValue("IDapis_userslist");
  const loadingauth = getValue("loadingauth");

  // Function to get the value from authCand if it exists
  const getValuecand = (key) => {
    try {
      return authCand?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  let NumSem = getValuecand("NumSem");
  let loadingauthcand = getValuecand("loadingauth");
  let Singup_active = getValuecand("Singup_active");
  let download_cert_scol_active = getValuecand("download_cert_scol_active");
  let EditingInfo_active = getValuecand("EditingInfo_active");
  let Eval_notes_active = getValuecand("Eval_notes_active");
  let download_cert_inscription_active = getValuecand("download_cert_inscription_active");
  let Singup_active_Dfep = getValuecand("Singup_active_Dfep");
  let download_cert_scol_active_Dfep = getValuecand("download_cert_scol_active_Dfep");
  let EditingInfo_active_Dfep = getValuecand("EditingInfo_active_Dfep");
  let Eval_notes_active_Dfep = getValuecand("Eval_notes_active_Dfep");
  let download_cert_inscription_active_Dfep = getValuecand("download_cert_inscription_active_Dfep");
  let Singup_active_Mfep = getValuecand("Singup_active_Mfep");
  let download_cert_scol_active_Mfep = getValuecand("download_cert_scol_active_Mfep");
  let EditingInfo_active_Mfep = getValuecand("EditingInfo_active_Mfep");
  let Eval_notes_active_Mfep = getValuecand("Eval_notes_active_Mfep");
  let download_cert_inscription_active_Mfep = getValuecand("download_cert_inscription_active_Mfep");
  let crypted_id = getValuecand("crypted_id");

  useEffect(() => {
    // Check if IDCandidat is valid and authCandData is not loading
    if (IDetablissement !== null && IDetablissement !== undefined && Number(IDetablissement) > 0 && !authCandData["loadingauth"]) {
      // Deep comparison of authCand and authCandData to prevent unnecessary state updates
      if (JSON.stringify(authCand) !== JSON.stringify(authCandData)) {

        setAuthCand(authCandData); // Update authCand state when IDCandidat is valid and authCand has changed
      }
    }
  }, [IDetablissement, authCandData, authCand]); // Trigger useEffect when IDCandidat, authCandData, or authCand changes



  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState("");
  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [filteredRows, setFilteredRows] = useState([]);
  const [gridKey, setGridKey] = useState(0); // Add a key for the DataGrid

  const [uniqueNomEtValues, setUniqueNomEtValues] = useState([]);
  const [uniquesession_nomValues, setUniquesession_nomValues] = useState([]);

  const [Selectedsession_nomValue, setSelectedsession_nomValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الدورات",
  });

  const [uniqueSpecialiteValues, setuniqueSpecialiteValues] = useState([]);

  const [uniqueNomFormationValues, setuniqueNomFormationValues] = useState([]);
  const [uniquesemValues, setuniquesemValues] = useState([]);
  const [etablistvalues, setetablistvalues] = useState([]);

  const [selectedNomFormationValue, setselectedNomFormationValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الأنماط",
  });
  const [idsemsec, setidsemsec] = useState(0);

  const [selectedsemValue, setselectedsemValue] = useState({
    indexvalue: 0,
    value: 999,
    label: ""
  });

  const [selectedNomEtValue, setSelectedNomEtValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "",
  });
  const [selectedSpecialiteValue, setselectedSpecialiteValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "",
  });


  const [visibleRowId, setVisibleRowId] = React.useState(null);

  const togglePasswordVisibility = (id) => {
    setVisibleRowId((prevId) => (prevId === id ? null : id));
  };

  const [IDDFEP_selected, setIDDFEP_selected] = useState(null);

  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);

  const editedValueRef = useRef(editedValue);

  const [selectedOption, setSelectedOption] = useState(null);
  const [options_Wlylist, setoptions_Wlylist] = React.useState([]);


  useEffect(() => {

    const dfepData = [
      { IDDFEP: 0, Nom: 'كل الولايات', NomFr: 'Toutes les wilayas' },
      { IDDFEP: 1, Nom: 'أدرار', NomFr: 'Adrar' },
      { IDDFEP: 2, Nom: 'شلف', NomFr: 'Chlef' },
      { IDDFEP: 3, Nom: 'الأغواط', NomFr: 'Laghouat' },
      { IDDFEP: 4, Nom: 'أم البواقي', NomFr: 'Oum El Bouaghi' },
      { IDDFEP: 5, Nom: 'با تنة', NomFr: 'Batna' },
      { IDDFEP: 6, Nom: 'بجا ية', NomFr: 'Bejaia' },
      { IDDFEP: 7, Nom: 'بسكرة', NomFr: 'Biskra' },
      { IDDFEP: 8, Nom: 'بشا ر', NomFr: 'Bechar' },
      { IDDFEP: 9, Nom: 'البليدة', NomFr: 'Blida' },
      { IDDFEP: 10, Nom: 'البو ير ة', NomFr: 'Bouira' },
      { IDDFEP: 11, Nom: 'تمنراست', NomFr: 'Tamanrasset' },
      { IDDFEP: 12, Nom: 'تبسة', NomFr: 'Tebessa' },
      { IDDFEP: 13, Nom: 'تلمسان', NomFr: 'Tlemcen' },
      { IDDFEP: 14, Nom: 'تيارت', NomFr: 'Tiaret' },
      { IDDFEP: 15, Nom: 'تيزي وزو', NomFr: 'Tizi Ouzou' },
      { IDDFEP: 16, Nom: 'الجزا ئر', NomFr: 'Alger' },
      { IDDFEP: 17, Nom: 'الجلفة', NomFr: 'Djelfa' },
      { IDDFEP: 18, Nom: 'جيجل', NomFr: 'Jijel' },
      { IDDFEP: 19, Nom: 'سطيف', NomFr: 'Setif' },
      { IDDFEP: 20, Nom: 'سعيدة', NomFr: 'Saida' },
      { IDDFEP: 21, Nom: 'سكيكدة', NomFr: 'Skikda' },
      { IDDFEP: 22, Nom: 'سيدي بلعباس', NomFr: 'Sidi Bel Abbes' },
      { IDDFEP: 23, Nom: 'عنابة', NomFr: 'Annaba' },
      { IDDFEP: 24, Nom: 'قالمة', NomFr: 'Guelma' },
      { IDDFEP: 25, Nom: 'قسنطينة', NomFr: 'Constantine' },
      { IDDFEP: 26, Nom: 'المدية', NomFr: 'Medea' },
      { IDDFEP: 27, Nom: 'مستغانم', NomFr: 'Mostaganem' },
      { IDDFEP: 28, Nom: 'مسيلة', NomFr: 'M\'Sila' },
      { IDDFEP: 29, Nom: 'معسكر', NomFr: 'Mascara' },
      { IDDFEP: 30, Nom: 'ورقلة', NomFr: 'Ouargla' },
      { IDDFEP: 31, Nom: 'وهران', NomFr: 'Oran' },
      { IDDFEP: 32, Nom: 'البيض', NomFr: 'El Bayadh' },
      { IDDFEP: 33, Nom: 'إليزي', NomFr: 'Illizi' },
      { IDDFEP: 34, Nom: 'برج بوعريريج', NomFr: 'Bordj Bou Arréridj' },
      { IDDFEP: 35, Nom: 'بومرداس', NomFr: 'Boumerdes' },
      { IDDFEP: 36, Nom: 'الطارف', NomFr: 'El Taref' },
      { IDDFEP: 37, Nom: 'تيندوف', NomFr: 'Tindouf' },
      { IDDFEP: 38, Nom: 'تيسمسيلت', NomFr: 'Tissemsilt' },
      { IDDFEP: 39, Nom: 'الوادي', NomFr: 'El Oued' },
      { IDDFEP: 40, Nom: 'خنشلة', NomFr: 'Khenchela' },
      { IDDFEP: 41, Nom: 'سوق أهراس', NomFr: 'Souk Ahras' },
      { IDDFEP: 42, Nom: 'تيبازة', NomFr: 'Tipaza' },
      { IDDFEP: 43, Nom: 'ميلة', NomFr: 'Mila' },
      { IDDFEP: 44, Nom: 'عين الدفلى', NomFr: 'Ain Defla' },
      { IDDFEP: 45, Nom: 'النعامة', NomFr: 'Naâma' },
      { IDDFEP: 46, Nom: 'عين تيموشنت', NomFr: 'Ain Temouchent' },
      { IDDFEP: 47, Nom: 'غرداية', NomFr: 'Ghardaia' },
      { IDDFEP: 48, Nom: 'غليزان', NomFr: 'Relizane' },
      { IDDFEP: 49, Nom: 'تميمون', NomFr: 'Timimoun' },
      { IDDFEP: 50, Nom: 'برج باجي مختار', NomFr: 'Bordj Badji Mokhtar' },
      { IDDFEP: 51, Nom: 'أولاد جلال', NomFr: 'Ouled  Djellal' },
      { IDDFEP: 52, Nom: 'بني عباس', NomFr: 'Béni Abbès' },
      { IDDFEP: 53, Nom: 'عين صالح', NomFr: 'In Salah' },
      { IDDFEP: 54, Nom: 'عين قزام', NomFr: 'In Guezzam' },
      { IDDFEP: 55, Nom: 'توقرت', NomFr: 'Touggourt' },
      { IDDFEP: 56, Nom: 'جانت', NomFr: 'Djanet' },
      { IDDFEP: 57, Nom: 'المغير', NomFr: 'El M’Ghaier' },
      { IDDFEP: 58, Nom: 'المنيعة', NomFr: 'El Meniaa' }

    ];

    const options_Wly = dfepData.map((Wlylist, i) => ({
      value: Wlylist.IDDFEP,
      label: Wlylist.IDDFEP + '- ' + Wlylist.Nom + '- ' + Wlylist.NomFr,
      indexvalue: i
    }));


    setoptions_Wlylist(options_Wly);
  }, []);


  const onwlyChanged = (event, values) => {
    if (values) {
      setRows([]); // Clear rows before setting new rows
      setFilteredRows([]);

      setSelectedNomEtValue({
        indexvalue: 0,
        value: 999,
        label: "",
      });

      setUniqueNomEtValues([]);
      setSelectedOption(values);
      setIDDFEP_selected(values.value);
    } else {
      return;
    }
  };


  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);


  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );


  const navigate = useNavigate();

  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl",
      },
    },
    inputRoot: {
      color: "#004d40",

      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#004d40",
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d40",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#00796b",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d00",
      },
    },
  }));

  const classes_listdfep = useStyles_listdfep();


  ;

  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      // Increment the key to force a remount of the DataGrid
      setGridKey((prevKey) => {
        ////alert(`Previous key: ${prevKey}`);
        return prevKey + 1;
      });
    }
  }, [filteredRows]);

  useEffect(() => {
    let partosend;
    if (selectedNomEtValue.value == null || Number(selectedNomEtValue.value) <= 0
      || Number(selectedNomEtValue.value) === 999) {

      if (IDDFEP_selected != null && Number(selectedNomEtValue.value) > 0 && Number(IDDFEP_selected) > 0) {
        partosend = IDDFEP_selected + "/0/0";
      }
      else if (IDDFEP_selected != null && Number(IDDFEP_selected) === 0) {
        partosend = "0/0/1";
      }
      else {
        return;
      }
    } else {
      if (selectedNomEtValue.value != null && Number(selectedNomEtValue.value) > 0) {
        partosend = "0/" + selectedNomEtValue.value + "/0";
      }
    }



    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows
    setFilteredRows([]);
    //apiUrletab
    //apiUrl

    fetch(apiUrl + "/" + partosend, {
      withCredentials: true, // Send cookies with the request
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Log the data.data to the console
        //console.log(data.data);

        const apiRows = data.data.map((item, index) => ({
          id: item.IDEquipement,
          id_rows: index + 1,
          ID: item.IDEquipement,


          IDetablissement: item.IDetablissement,
          EquipementNom: item.EquipementNom,
          IDEquipement: item.IDEquipement,
          IDDFEP: item.IDDFEP,
          EtablissementNom: item.EtablissementNom,
          EtablissementCode: item.EtablissementCode,
          BrancheNom: item.BrancheNom,
          SpecialiteNom: item.SpecialiteNom,
          LocauxNom: item.LocauxNom,
          EquipementExploiNom: item.EquipementExploiNom,
          equipement_etatNom: item.equipement_etatNom,
          Equipement_obsNom: item.Equipement_obsNom,
          EquipementCode: item.EquipementCode,
          NomFr: item.NomFr,
          DateMiseEnexploi: item.DateMiseEnexploi,
          Datereception: item.Datereception,
          NumPvReception: item.NumPvReception,
          DatePvReception: item.DatePvReception,
          Obs: item.Obs,
          Datenombrement: item.Datenombrement,
          Numpvnombrement: item.Numpvnombrement,
          Validation: item.Validation,
          DateFIn: item.DateFIn,
          ValidationDfp: item.ValidationDfp,
          IDBranche: item.IDBranche,
          IDEquipementExploi: item.IDEquipementExploi,
          Complet: item.Complet,
          IDSpecialite: item.IDSpecialite,
          DateInstalation: item.DateInstalation,
          IDLocaux: item.IDLocaux,
          IDequipement_etat: item.IDequipement_etat,
          IDFournisseur: item.IDFournisseur,
          IDequipement_obs: item.IDequipement_obs,
          existepvrecoulmentequipement: item.existepvrecoulmentequipement,
          Proposetransfert: item.Proposetransfert,
          datepvreforme: item.datepvreforme,
          NumPvreforme: item.NumPvreforme,
          Existemagasin: item.Existemagasin,

          actions: "",
        }));
        setRows(apiRows);
        ///rowsdata=apiRows;
        setFilteredRows(apiRows);
        // Extract unique 'Nom_Et' values
        /// const uniqueValues = [...new Set(apiRows.map(row => row.code_etab))];

        setloadingdata_mfep(false);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        // This will run regardless of success or failure
        setloadingdata_mfep(false);
      });
  }, [selectedNomEtValue.value, IDDFEP_selected]);

  useEffect(() => {
    //alert(IDetablissement);
    if (IDetablissement == null || Number(IDetablissement) <= 0
    ) {
      return; // Or any other handling you might want
    }
    //alert(selectedNomEtValue.value);
    //alert(IDetablissement);
    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows
    setFilteredRows([]);
    //apiUrletab
    //apiUrl

    fetch(apiUrletab + "/0/" + IDetablissement + "/0", {
      withCredentials: true, // Send cookies with the request
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Log the data.data to the console
        // console.log(data.data);

        const apiRows = data.data.map((item, index) => ({
          id: item.IDEquipement,
          id_rows: index + 1,
          ID: item.IDEquipement,

          IDetablissement: item.IDetablissement,
          EquipementNom: item.EquipementNom,
          IDEquipement: item.IDEquipement,
          IDDFEP: item.IDDFEP,
          EtablissementNom: item.EtablissementNom,
          EtablissementCode: item.EtablissementCode,
          BrancheNom: item.BrancheNom,
          SpecialiteNom: item.SpecialiteNom,
          LocauxNom: item.LocauxNom,
          EquipementExploiNom: item.EquipementExploiNom,
          equipement_etatNom: item.equipement_etatNom,
          Equipement_obsNom: item.Equipement_obsNom,
          EquipementCode: item.EquipementCode,
          NomFr: item.NomFr,
          DateMiseEnexploi: item.DateMiseEnexploi,
          Datereception: item.Datereception,
          NumPvReception: item.NumPvReception,
          DatePvReception: item.DatePvReception,
          Obs: item.Obs,
          Datenombrement: item.Datenombrement,
          Numpvnombrement: item.Numpvnombrement,
          Validation: item.Validation,
          DateFIn: item.DateFIn,
          ValidationDfp: item.ValidationDfp,
          IDBranche: item.IDBranche,
          IDEquipementExploi: item.IDEquipementExploi,
          Complet: item.Complet,
          IDSpecialite: item.IDSpecialite,
          DateInstalation: item.DateInstalation,
          IDLocaux: item.IDLocaux,
          IDequipement_etat: item.IDequipement_etat,
          IDFournisseur: item.IDFournisseur,
          IDequipement_obs: item.IDequipement_obs,
          existepvrecoulmentequipement: item.existepvrecoulmentequipement,
          Proposetransfert: item.Proposetransfert,
          datepvreforme: item.datepvreforme,
          NumPvreforme: item.NumPvreforme,
          Existemagasin: item.Existemagasin,

          actions: "",
        }));
        setRows(apiRows);
        ///rowsdata=apiRows;
        setFilteredRows(apiRows);
        // Extract unique 'Nom_Et' values
        /// const uniqueValues = [...new Set(apiRows.map(row => row.code_etab))];

        setloadingdata_mfep(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [IDetablissement]);

  const apiUrl_getSection_Semestre_Section = `${API_BASE_URL}/api/getSection_Semestre_Section`;



  const apiUrl_listetab = `${API_BASE_URL}/api/getlistofalletab_byiddfep`;

  useEffect(() => {

    if (IDDFEP_selected === null || Number(IDDFEP_selected) <= 0) {
      return;
    }



    fetch(apiUrl_listetab + "/" + IDDFEP_selected, {
      withCredentials: true, // Send cookies with the request
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Log the data.data to the console
        //console.log(data.data);

        // Map the data to the desired format
        const options_sem = data.data.map((semest, index) => ({
          value: semest.IDetablissement,
          label: `${semest.Code} | ${semest.Nom} | ${semest.CommunnNom} `,
          indexvalue: index,
        }));

        // Update the state with the mapped data
        setUniqueNomEtValues(options_sem);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [IDDFEP_selected]);



  /*
  useEffect(() => {
    if(selectedSpecialiteValue==null && uniqueSpecialiteValues.length>0 ){
      alert("uniqueSpecialiteValues.length");
    setselectedSpecialiteValue({
      indexvalue: 0,
      value: 999,
      label: "كل التخصصات",
    });
    }

    
  }, [uniqueNomEtValues,uniqueNomFormationValues,uniquesemValues,uniqueSpecialiteValues]);
  */

  useEffect(() => {
    if (
      selectedNomFormationValue &&
      selectedNomEtValue &&
      selectedsemValue &&
      selectedSpecialiteValue &&
      Selectedsession_nomValue
    ) {
      // Reset filteredRows
      setFilteredRows([]);

      // Check for default values
      const isNomFormationDefault =
        Number(selectedNomFormationValue.value) === 999;
      const isNomEtDefault = Number(selectedNomEtValue.value) === 999;
      const isSemDefault = Number(selectedsemValue.value) === 999;
      const isSpecDefault = Number(selectedSpecialiteValue.value) === 999;
      const isSessionDefault = Number(Selectedsession_nomValue.value) === 999;

      // Filter rows based on selection
      const filteredData = rows.filter((row) => {
        if (
          isNomFormationDefault &&
          isNomEtDefault &&
          isSemDefault &&
          isSpecDefault &&
          isSessionDefault
        ) {
          // No filtering needed, set filteredRows to all rows
          return true;
        } else {
          // Filter rows based on selected values
          return (
            (isNomFormationDefault ||
              Number(row.IDMode_formation) === Number(selectedNomFormationValue.value)) &&
            (isNomEtDefault || row.code_etab === selectedNomEtValue.value) &&
            (isSemDefault || Number(row.NumSem) === Number(selectedsemValue.value)) &&
            (isSpecDefault ||
              Number(row.IDSpecialite) === Number(selectedSpecialiteValue.value)) &&
            (isSessionDefault ||
              Number(row.IDSession) === Number(Selectedsession_nomValue.value))
          );
        }
      });

      setFilteredRows(filteredData);
    }
  }, [
    selectedNomEtValue,
    selectedNomFormationValue,
    selectedsemValue,
    selectedSpecialiteValue,
    Selectedsession_nomValue,
  ]);

  const handlesessionChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setSelectedsession_nomValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected session Value:", newValue);
    }
  };

  const handleSpecialiteChange = (event, newValue) => {
    if (newValue) {
      //alert(newValue.value);
      // Handle the change according to newValue
      setselectedSpecialiteValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Specialite Value:", newValue);
    }
  };

  const handleNomEtChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setSelectedNomEtValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state


      // You can perform further actions based on the selected value if needed
      //console.log("Selected Nom Et Value:", newValue);
    }
  };

  const handleNomFormationChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedNomFormationValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom formation Value:", newValue);
    }
  };

  const handlesemChange = (event, newValue) => {
    if (newValue) {
      setidsemsec(newValue.value);
      //alert(newValue.value);
      // Handle the change according to newValue
      setselectedsemValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state
      setselectedSpecialiteValue({
        indexvalue: 0,
        value: 999,
        label: "",
      });
      // You can perform further actions based on the selected value if needed
      console.log("Selected semestre Value:", newValue);
    }
  };

  /*
  const handleNomEtChange = event => {
    ///alert(event.target.value)
    
    setSelectedNomEtValue(event.target.value);
  };*/

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.commit
    ) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
      }
    }
  };

  const handlecert_inscription = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      // Retrieve the token from sessionStorage
      const tokenEtab = sessionStorage.getItem("authData_73xJ#E");

      // Store the token in localStorage
      localStorage.setItem("authData_73xJ#E", tokenEtab);

      /// navigate(`/ETATCertificatInscriptionPDF/${crypted_id}`);

      if (tokenEtab) {
        // Extract NomAr value from the row
        const { crypted_id } = rowToid;

        // Open link in a new tab
        window.open(`/ETATCertificatInscriptionPDF/${crypted_id}`, "_blank");
      } else {
        // Handle case where tokenEtab is not available
        console.error("TokenEtab not found in sessionStorage.");
      }
    }
  };

  const handlebtn_relevenote = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypt_IDApprenant_Section_semstre } = rowToid;
      const { crypted_id } = rowToid;
      window.open(`/Releve2notes/${crypt_IDApprenant_Section_semstre}`, "_blank");
      //alert(IDApprenant_Section_semstre);
    }
  }

  const handlecert_certscolaire = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      // Retrieve the token from sessionStorage
      const tokenEtab = sessionStorage.getItem("authData_73xJ#E");

      // Store the token in localStorage
      localStorage.setItem("authData_73xJ#E", tokenEtab);

      /// navigate(`/ETATCertificatScolaritePDF/${crypted_id}`);

      if (tokenEtab) {
        // Extract NomAr value from the row
        const { crypted_id } = rowToid;

        // Open link in a new tab
        window.open(`/ETATCertificatScolaritePDF/${crypted_id}`, "_blank");
      } else {
        // Handle case where tokenEtab is not available
        console.error("TokenEtab not found in sessionStorage.");
      }
    }
  };

  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };

  const [idsaved, setidsaved] = useState(0);

  const [confirmationTrigger, setConfirmationTrigger] = useState(false);

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);
    ///setConfirmationTrigger(true); // Set the trigger for confirmation
  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);

  const handleDeleteClick = (id) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;

      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [addRecordClicked, setAddRecordClicked] = useState(false);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>
          <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider>
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: "0px" }} />
                  </Typography>
                </Grid>
              </Divider>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                }}
              >
                هل أنت متأكد من أنك تريد حذف{" "}
                <span style={{ color: "black" }}>
                  {deleted_PrenomAr} {deleted_NomAr}؟
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </CacheProvider>
    );
  };

  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map((col) => ({
      name: col.field,
      headerName: col.headerName,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const newRow = {};
        relevantColumns.forEach((col) => {
          newRow[col.headerName] = row[col.name];
        });
        return newRow;
      })
    );

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: "Calibri",
        sz: 11,
        bold: true,
        color: {
          rgb: "FFFFAA00",
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  // Function to handle file download
  const downloadcvIDEncadrement = async (namefiledwn) => {
    try {
      const response = await axios({
        url: `${API_BASE_URL}/api/downloadcvencadrbyetab/` + namefiledwn,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', namefiledwn + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('فشل تحميل الملف');
    }
  };


  const columns = [
    {
      field: "id_rows",
      headerName: "رقم",
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "IDEquipement",
      headerName: "الرمز",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    }
    ,

    {
      field: "EquipementNom",
      headerName: "تسمية الجهاز",
      minWidth: 220,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "EquipementExploiNom",
      headerName: "الاستغلال",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    }
    ,

    {
      field: "equipement_etatNom",
      headerName: "الحالة",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    }

    ,
    {
      field: "Datereception",
      headerName: "تاريخ الإستلام",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "DatePvReception",
      headerName: "تاريخ التثبيت",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "DateMiseEnexploi",
      headerName: "تاريخ أول إستغلال",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Equipement_obsNom",
      headerName: "ملاحظة",
      minWidth: 220,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "BrancheNom",
      headerName: "الشعبة",
      minWidth: 220,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "SpecialiteNom",
      headerName: "التخصص",
      minWidth: 220,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "LocauxNom",
      headerName: "المقر البيداغوحي",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    }
    ,
    {
      field: "EtablissementCode",
      headerName: "رمز المؤسسة",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    }
    ,
    {
      field: "EtablissementNom",
      headerName: "تسمية المؤسسة",
      minWidth: 400,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    }



    /*
     id: item.IDEquipement,
              id_rows: index + 1,
              ID: item.IDEquipement,
             EtablissementNom
              IDetablissement: item.IDetablissement,
              EquipementNom: item.EquipementNom,
              IDEquipement: item.IDEquipement,
              IDDFEP: item.IDDFEP,
              EtablissementCode: item.EtablissementCode,
              BrancheNom: item.BrancheNom,
              SpecialiteNom: item.SpecialiteNom,
              LocauxNom: item.LocauxNom,
              EquipementExploiNom: item.EquipementExploiNom,
              equipement_etatNom: item.equipement_etatNom,
              Equipement_obsNom: item.Equipement_obsNom,
              EquipementCode: item.EquipementCode,
              NomFr: item.NomFr,
              DateMiseEnexploi: item.DateMiseEnexploi,
              Datereception: item.Datereception,
              NumPvReception: item.NumPvReception,
              DatePvReception: item.DatePvReception,
              Obs: item.Obs,
              Datenombrement: item.Datenombrement,
              Numpvnombrement: item.Numpvnombrement,
              Validation: item.Validation,
              DateFIn: item.DateFIn,
              ValidationDfp: item.ValidationDfp,
              IDBranche: item.IDBranche,
              IDEquipementExploi: item.IDEquipementExploi,
              Complet: item.Complet,
              IDSpecialite: item.IDSpecialite,
              DateInstalation: item.DateInstalation,
              IDLocaux: item.IDLocaux,
              IDequipement_etat: item.IDequipement_etat,
              IDFournisseur: item.IDFournisseur,
              IDequipement_obs: item.IDequipement_obs,
              existepvrecoulmentequipement: item.existepvrecoulmentequipement,
              Proposetransfert: item.Proposetransfert,
              datepvreforme: item.datepvreforme,
              NumPvreforme: item.NumPvreforme,
              Existemagasin: item.Existemagasin,
    */


  ];

  function generateRandomString(length = 6) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: "success" });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  useEffect(() => {
    if (!loadingauth) {
      if (
        (IDUtilisateur === null || !Number(IDUtilisateur) === 999)
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }




    }
  }, [
    IDUtilisateur,
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <Header

      />

      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <Box
            sx={{
              '& .super-app-theme--header': {
                fontFamily: 'Cairo',
                fontSize: '20px',
                fontWeight: 'bold', // Make the font bold
                backgroundColor: '#004d40', // Light green background color

                borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
                fontWeight: 900,
                color: '#FFFFFF'
              },
            }}
          >
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={theme_datagrid}>
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    sx={{
                      marginTop: 12,
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: "flex",
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        "& .MuiAlert-icon": { marginLeft: "10px" },
                        "& .MuiAlert-action": { marginRight: "8px" },
                      }} // Adjust padding here
                    />
                  </Snackbar>
                )}

                <Grid container>
                  <Grid item sx={{ width: "100%" }}>
                    <Divider sx={{ mt: "20px" }}>
                      <Grid item>
                        <Typography
                          style={{
                            color: "#004d40",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: "25px",
                          }}
                          variant="h3"
                          gutterBottom
                        >

                          <ConstructionIcon sx={{ mr: "5px" }} />
                          ملف التجهيزات
                        </Typography>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontFamily: '"Tajawal", sans-serif',
                            color: "#004d40",
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {EndTimeupdatedb && (
                            <>
                              آخر جلب للمعلومات من منصة تسيير: {formatArabicDate(EndTimeupdatedb)}

                            </>
                          )}
                        </Typography>

                      </Grid>
                    </Divider>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    align="center"
                    justifyContent="center"
                    sx={{ width: "100%", marginBottom: "10px" }}
                  >


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mx: 2 }}>
                      <Grid container direction="column" >
                        {/* Autocomplement Wilayat  */}

                        <CacheProvider value={cacheRtl}>


                          <Autocomplete
                            noOptionsText={'حدث الصفحة مرة أخرى'}
                            classes={classes_listdfep}
                            id="combo-box-demowly"
                            onChange={onwlyChanged}
                            value={selectedOption} // Use the value prop to set the selected option
                            options={options_Wlylist}

                            defaultValue={options_Wlylist[0]} // Set the defaultValue to the first option
                            sx={{ mt: 1, mb: 1 }}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? '#f2f9f2' : '#FFFFFF', // ألوان خلفية خضراء فاتحة ومنسجمة
                                    color: '#004D40', // لون النص داكن ليتناسب مع الخلفية الفاتحة
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}


                                >
                                  {indexvalue === -1 ? '- ' + label : label}
                                </span>
                              );
                            }}

                            renderInput={(params) => (

                              <TextField
                                {...params}

                                placeholder="إختر ولاية"
                                error={!selectedOption}
                                helperText={
                                  !selectedOption
                                    ? 'إختر ولاية'
                                    : ''
                                }
                                tabIndex={1}
                                dir="rtl" // Add dir="rtl" here

                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  '& .MuiFormHelperText-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '14px',
                                    color: '#004d40',
                                    fontWeight: 700
                                  },

                                  '& .MuiFormLabel-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    color: '#004d40'
                                  },
                                  '& .MuiInputBase-input': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '18px',
                                    color: '#004d40',
                                    fontWeight: 700
                                  },
                                  '& label.Mui-focused': {
                                    fontSize: '16px',
                                    color: '#004d40',
                                    fontWeight: 700
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#004d40',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: '16px',
                                      borderColor: '#004d40',
                                      borderWidth: "2px",
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'green',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: 'green',
                                    },
                                  }
                                }}

                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}

                              />

                            )}


                          />




                        </CacheProvider>
                      </Grid>
                    </Grid>


                    {uniqueNomEtValues.length > 0 ? (
                      <Grid item xs={10} >
                        <Autocomplete
                          noOptionsText={"حدد المؤسسة"}
                          options={uniqueNomEtValues}
                          onChange={handleNomEtChange}
                          value={selectedNomEtValue}
                          defaultValue={uniqueNomEtValues[0]} // Set the defaultValue to the first option
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="المؤسسات"


                              sx={{
                                mt: 1,
                                mb: 1,
                                '& .MuiFormHelperText-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '14px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },

                                '& .MuiFormLabel-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '16px',
                                  color: '#004d40'
                                },
                                '& .MuiInputBase-input': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '18px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },
                                '& label.Mui-focused': {
                                  fontSize: '16px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#004d40',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    borderColor: '#004d40',
                                    borderWidth: "2px",
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'green',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'green',
                                  },
                                }
                              }}

                              fullWidth
                              InputLabelProps={{
                                style: {
                                  color: "#004d40",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  color: "#004d40",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                },
                              }}

                            />
                          )}
                          renderOption={(props, option) => {
                            const { label, indexvalue } = option;
                            return (
                              <span
                                {...props}
                                style={{
                                  backgroundColor: indexvalue % 2 === 0 ? '#f2f9f2' : '#FFFFFF', // ألوان خلفية خضراء فاتحة ومنسجمة
                                  color: '#004D40', // لون النص داكن ليتناسب مع الخلفية الفاتحة
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                }}
                              >
                                {indexvalue === -1 ? label : label}
                              </span>
                            );
                          }}
                        />
                      </Grid>
                    ) : null}


                  </Grid>

                  {loadingdata_mfep ? (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress color="success" />
                      </Box>
                    </>
                  ) : null}

                  <Grid container>
                    <Grid item sx={{ width: "100%" }}>


                      <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme_datagrid}>
                          <div dir="rtl" style={{ height: '100%', width: '100%', minHeight: 300 }}>

                            <DataGrid
                              key={gridKey} // Use the key property to force remount
                              locale="ar-SD"
                              rows={filteredRows}
                              columns={columns}
                              editMode="row"
                              rowModesModel={rowModesModel}
                              onRowModesModelChange={handleRowModesModelChange}
                              onRowEditStop={handleRowEditStop}
                              getRowId={(row) => row.id}
                              getRowClassName={(params) =>
                                parseInt(params.row.id_rows, 10) % 2 === 0
                                  ? "even-row"
                                  : "odd-row"
                              }
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              slotProps={{
                                toolbar: {
                                  setRows,
                                  setRowModesModel,
                                  columns,
                                  handleExportExcel,
                                },
                              }}

                              sx={{
                                boxShadow: 2,
                                border: 2,

                                borderColor: 'green', // Green border color
                                overflowX: 'scroll',

                                '& .MuiDataGrid-cell': {
                                  fontFamily: 'Tajawal',
                                  fontSize: '20px',

                                  backgroundColor: 'rgba(255, 255, 255, 0.65)', // Keep the background color of cells as is
                                  borderRight: '1px solid #ccc', // Keep the right border as is
                                },
                                '& .MuiDataGrid-row:hover': {
                                  backgroundColor: '#004d40', // Green background color when row is hovered
                                },

                                '& .even-row': {
                                  backgroundColor: '#e8f5e9', // Light green background color for even rows
                                },
                                '& .odd-row': {
                                  backgroundColor: '#ffffff', // Keep the background color for odd rows as is
                                },
                              }}


                              initialState={{
                                pagination: {
                                  paginationModel: { pageSize: 25, page: 0 },
                                },
                                columns: {
                                  columnVisibilityModel: {
                                    // Hide columns blog and tiktok, the other columns will remain visible
                                    NoteMemoire: false,
                                    NoteSoutenance: false,
                                    NoteStage: false,
                                  },
                                },
                              }}
                            />


                            <MyDialog
                              open={dialogOpen}
                              handleClose={handleDialogClose}
                              prenom={dialogData.prenom}
                              nom={dialogData.nom}
                              urls={dialogData.urls && dialogData.urls.length > 0 ? dialogData.urls : undefined}
                              text={dialogData.text || undefined}
                            />

                          </div>
                        </ThemeProvider>
                      </CacheProvider>

                     

                  </Grid>
                </Grid>
              </Grid>


            </ThemeProvider>
          </CacheProvider>
      </Box>
      <br />
      <Footer />
    </div >
      <SaveConfirmationDialog
        open={isSaveConfirmationOpen}
        onClose={handleSaveConfirmationResponse}
      />
      </Box >
    </>
  );
}

export default ShowAllEquipmfep;

import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { APP_PREFIX } from './config'; // Adjust the path if necessary
import useAuthentication_etab from './useAuthentication_etab';
import { numberToString } from 'pdf-lib';


const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));


const Cardmoutakawin = () => {


  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();



  const { cryptedId } = useParams();
  const [result, setresult] = useState(null);
  const [result_dfep, setresult_dfep] = useState(null);

  const [result_mfep, setresult_mfep] = useState(null);

  const [Annee_formation_Nom, setAnnee_formation_Nom] = useState(null);

  const [download_cert_scol_active_state, setdownload_cert_scol_active_state] = useState(null);
  const [download_cert_scol_active_dfep_state, setdownload_cert_scol_active_dfep_state] = useState(null);
  const [download_cert_scol_active_mfep_state, setdownload_cert_scol_active_mfep_state] = useState(null);


  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const navigate = useNavigate();
  const [loadcrypted, setloadcrypted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/getbycrypted_id_public/${cryptedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        //alert(JSON.stringify(data.data)); // Alerting the fetched data as a JSON string
        /////console.log(data.data);
        setresult(data.data);
        setresult_dfep(data.data_dfep);
        setresult_mfep(data.data_mfep);

        setdownload_cert_scol_active_state(data.data.download_cert_scol_active);
        setdownload_cert_scol_active_dfep_state(data.data_dfep.download_cert_scol_active_dfep);
        setdownload_cert_scol_active_mfep_state(data.data_mfep.download_cert_scol_active_mfep);

        /*
        if (Number(data.data.download_cert_scol_active) === 0 
        || Number(data.data_dfep.download_cert_scol_active_dfep) === 0 
        || Number(data.data_mfep.download_cert_scol_active_mfep) === 0 ) {
        navigate("/");
      }
*/
        if (Number(data.data.IDApprenant_Fin) > 0) {
          navigate("/");
        }

        setAnnee_formation_Nom(data.Annee_formation_Nom);
        setloadcrypted(true);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      }
    };

    if (cryptedId) {
      fetchData();
    }

  }, [cryptedId]);



  useEffect(() => {

    if (result && !pdfUrl && !loadingauth) { // Only generate PDF if result is set and PDF URL is not already generated


      if (((Number(result.download_cert_scol_active) != 0
        || result.download_cert_scol_active == null)
        || (Number(result_dfep.download_cert_scol_active_dfep) != 0)
        || (Number(result_mfep.download_cert_scol_active_mfep) != 0)
      ) &&
        (localStorage.getItem(APP_PREFIX + 'download_cert_scol_active') != "0"
          || localStorage.getItem(APP_PREFIX + 'download_cert_scol_active') == null)) {
        //navigate('/');
      }
      else {
        localStorage.removeItem(APP_PREFIX + 'download_cert_scol_active');
      }





      // Set card dimensions in mm
      const cardWidth = 88;  // Increased width for better layout
      const cardHeight = 58; // Increased height for better layout



      // Create a new jsPDF instance with custom page size
      const pdf = new jsPDF({
        orientation: 'landscape', // or 'landscape' portrait
        unit: 'mm', // Use millimeters for the unit
        format: [cardWidth, cardHeight], // Set the page size to your card dimensions
      });

      // Set document information
      pdf.setProperties({
        title: 'بطاقة المتكون',
        subject: 'بطاقة المتكون',
        author: 'MFEP',
      });

      pdf.setLanguage('ar-SA')


      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;
      const fontUrl_majallab = `${process.env.PUBLIC_URL}/majallab.ttf`;

      const fontUrl_Cairo_Black = `${process.env.PUBLIC_URL}/Cairo-Black.ttf`;
      const fontUrl_Cairo_Bold = `${process.env.PUBLIC_URL}/Cairo-Bold.ttf`;

      const fontUrl_Tajawal_Bold = `${process.env.PUBLIC_URL}/Tajawal-Bold.ttf`;
      const fontUrl_Cairo_ExtraBold = `${process.env.PUBLIC_URL}/Cairo-ExtraBold.ttf`;
      const FrankRuhlLibre_Black = `${process.env.PUBLIC_URL}/FrankRuhlLibre_Black.ttf`;

      const credit_card_regular = `${process.env.PUBLIC_URL}/credit_card_regular.ttf`;
      const Hacen_Tunisia = `${process.env.PUBLIC_URL}/Hacen_Tunisia.ttf`;
      const Helvetica_Bold = `${process.env.PUBLIC_URL}/Helvetica_Bold.ttf`;


      pdf.addFont(fontUrl, 'Amiri', 'normal');
      // Draw Certificate title
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');
      pdf.addFont(fontUrl_Cairo_Black, 'Cairo_Black', 'normal');
      pdf.addFont(fontUrl_Cairo_Bold, 'Cairo_Bold', 'normal');
      pdf.addFont(fontUrl_Tajawal_Bold, 'Tajawal_Bold', 'normal');
      pdf.addFont(fontUrl_Cairo_ExtraBold, 'Cairo_ExtraBold', 'normal');
      pdf.addFont(fontUrl_majallab, 'Sakkal majallab', 'normal');
      pdf.addFont(FrankRuhlLibre_Black, 'FrankRuhlLibre_Black', 'normal');
      pdf.addFont(credit_card_regular, 'credit_card_regular', 'normal');
      pdf.addFont(Hacen_Tunisia, 'Hacen_Tunisia', 'normal');
      pdf.addFont(Helvetica_Bold, 'Helvetica_Bold', 'normal');


      //pdf.setFont('arabic_ejaza'); // Set font



      // Set the title of the browser tab
      document.title = 'بطاقة متكون';



      // Define the position where the card will be drawn (centered at the top of the page)
      const pageWidth = pdf.internal.pageSize.getWidth();
      const cardX = (pageWidth - cardWidth) / 2; // Center X position
      const cardY = 0;  // Y position in mm from the top margin

      // Draw the card background
      //const imageUrl_bgcardcand = require('../src/img/bgcardcand.jpg');
      //pdf.setFillColor(255, 255, 255); // White background
      //pdf.rect(cardX, cardY, cardWidth, cardHeight, 'F'); // Fill the rectangle

      // Load and add the background image

      const imageUrl_bgcardcand = require('../src/img/bgcardcand.jpg'); // Load image from file
      const imageUrl_algeriacardmap = require('../src/img/algeriacardmap.jpg'); // Load image from file

      // Set the card background image
      pdf.addImage(imageUrl_algeriacardmap, 'JPEG', cardX, cardY, cardWidth, cardHeight);

      // Set rounded rectangle border
      /* const borderRadius = 5; // Set corner radius
       pdf.setDrawColor(240, 240, 240); // Light gray color for border
       pdf.setLineWidth(0.2); // Set border thickness
       pdf.setFillColor(250, 250, 250); // Optional: white background (will be overwritten by the image)
       pdf.roundedRect(cardX, cardY, cardWidth, cardHeight, borderRadius, borderRadius, 'FD'); // Draw filled rounded rectangle
 */
      //const imageUrl_flagdz = require('../src/img/flag_dz.png'); // Left image
      //const imageUrl_logmfep = require('../src/img/logo.png'); // Right image
      const imageUrl_flagdz = require('../src/img/republicstamp_flagdz.png'); // Left image
      const imageUrl_logmfep = require('../src/img/republicstamp.png'); // Right image



      // Set dimensions for the images
      const flagImageWidth = 8; // Width of the flag image
      const flagImageHeight = 8; // Height of the flag image
      const logoImageWidth = 8; // Width of the logo image
      const logoImageHeight = 8; // Height of the logo image
      const studentImageWidth = 15.4; // Width of the student image
      const studentImageHeight = 17.7; // Height of the student image

      // Position for images
      const flagImageX = cardX + 3; // X position for flag image
      const flagImageY = cardY + 3; // Y position for flag image
      const logoImageX = cardX + cardWidth - logoImageWidth - 3; // X position for logo image
      const logoImageY = cardY + 3; // Y position for logo image

      // Add images to the card
      // pdf.addImage(imageUrl_flagdz, 'JPEG', flagImageX, flagImageY, flagImageWidth, flagImageHeight);
      // pdf.addImage(imageUrl_logmfep, 'JPEG', logoImageX, logoImageY, logoImageWidth, logoImageHeight);

      // Centered text in the middle of the card
      pdf.setFontSize(9);

      pdf.setFont('Sakkal Majalla'); // Set font



      // Middle text
      //pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', cardX + cardWidth / 2, cardY + 5, { align: 'center' });

      // Move to new line
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed

      // Draw Ministry information centered
      //pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', cardX + cardWidth / 2, cardY + 8, { align: 'center' });

      pdf.setFontSize(8);
      const imageUrl = 'https://tachbik.mfp.gov.dz/api/getcandidatmemo/' + cryptedId + '.jpg';

      // Define the position and size for the student image
      const studentImageX = flagImageX + 1.2; // Align student image with flag
      const studentImageY = flagImageY + flagImageHeight + 2.5; // Below the flag image
      const borderRadius = 0.1; // 5px border radius
      const borderColor = '#90EE90'; // Light green color for the border (hex for lightgreen)
      const borderWidth = 0.1; // Thickness of the border

      // Set the stroke color for the border
      // pdf.setLineWidth(borderWidth);
      //pdf.setDrawColor(borderColor);

      // Draw the rounded rectangle (the border)
      // pdf.roundedRect(studentImageX , studentImageY , studentImageWidth + borderWidth, studentImageHeight , 'S');

      // Draw the student image inside the border
      pdf.addImage(imageUrl, 'JPEG', studentImageX, studentImageY, studentImageWidth, studentImageHeight);


      /*  if (result !== null && result.libelleArabe_Wly_Dfep) {
          pdf.text('مديرية التكوين والتعليم المهنيين لولاية ' + result.libelleArabe_Wly_Dfep, cardX + cardWidth / 2, cardY + 11, { align: 'center' });
        } else {
          pdf.text('مديرية التكوين والتعليم المهنيين لولاية ', cardX + cardWidth / 2, cardY + 11, { align: 'center' });
        }
        pdf.text(result.Nom_Et, cardX + cardWidth / 2, cardY + 14, { align: 'center' });
  
        pdf.setFontSize(16);
        pdf.setFont('Sakkal majallab');
   
        pdf.text('بطاقة المتكون', (cardX + (cardWidth + studentImageWidth) / 2), cardY + 22, { align: 'center' });
  
        pdf.setFontSize(12);
        */

      pdf.setFont('Sakkal Majalla');
      // Create QR code as a data URL
      const qrDataURL_card = document.getElementById('qrCode').toDataURL('image/png');

      // Set dimensions for the QR code image (smaller size)
      const qrCodeWidth = 12; // New width of the QR code image
      const qrCodeHeight = 12; // New height of the QR code image

      // Set QR code position based on card dimensions
      const qrCodeX_card = cardX + 6; // Align QR code with the left margin of the card
      const qrCodeY_card = studentImageY + studentImageHeight + 6.5; // Position below the student image with a margin

      // Add QR code image to the PDF
      pdf.addImage(qrDataURL_card, 'PNG', qrCodeX_card, qrCodeY_card, qrCodeWidth, qrCodeHeight);

      // Set text properties
      pdf.setFontSize(9);
      pdf.setTextColor(3, 20, 1);


      // Define text positions, right-aligned
      let textX = cardX + cardWidth - 13.4; // X position for right alignment
      let textY = cardY + 26; // Start text from the top margin of the card

      pdf.setFont('Sakkal majallab');

      pdf.text(result.NumIns.toString(), textX - 6.5, textY - 4.3, { align: 'right' });

      pdf.text(result.Nom_Ca, textX + 1.3, textY + 0.5, { align: 'right' });
      pdf.setFontSize(8);

      pdf.text(result.NomFr.toUpperCase(), textX - 53, textY + 0.5, { align: 'left' });
      pdf.setFontSize(9);

      textY += 4.3;
      pdf.text(result.Prenom, textX + 1, textY + 0.5, { align: 'right' });
      pdf.setFontSize(8);
      pdf.text(result.PrenomFr.toUpperCase(), textX - 53, textY + 0.5, { align: 'left' });
      pdf.setFontSize(9);

      textY += 5.5;
      textX -= 14;

      // Check if result.DateNais contains only numbers and "-", "/", or "\"
      if (/^[0-9\/\\-]+$/.test(result.DateNais)) {
        // If it contains only numbers and these characters, format the date
        const formattedDate = result.DateNais.replace(/\//g, '-');

        // Now use the formatted date in the PDF
        pdf.text(formattedDate, textX, textY, { align: 'right' });
        pdf.text(result.LieuNais + ' ', textX - formattedDate.length-2, textY, { align: 'right' });
      } else {
        // If it contains anything else (e.g., letters), print the original date
        pdf.text(result.DateNais, textX, textY, { align: 'right' });
        pdf.text(result.LieuNais + ' ', textX - result.DateNais.length-2, textY, { align: 'right' });
      }


      textY += 4.1;
      textX += 11.5;
      pdf.text(result.Nom, textX, textY, { align: 'right' });

      textY += 4.5;
      textX += 2.3;

      if (result.apprenant_Regime_Nom && result.apprenant_Regime_Nom.trim() !== "") {
        pdf.text(result.apprenant_Regime_Nom, textX, textY, { align: 'right' });
      } else {
        // Optionally, you can set a default value or handle it differently
        pdf.text('  ', textX, textY, { align: 'right' });
      }

      textY += 5;
      textX -= 9;
      pdf.text(Annee_formation_Nom, textX, textY, { align: 'right' });


      /* 
            pdf.setFont('Sakkal Majalla'); // Set 
            const nometprenom = " :الاسم و اللقب";
            pdf.text(nometprenom, textX, textY, { align: 'right' });
      
            pdf.setFont('Sakkal majallab');
            pdf.text(result.Prenom + ' ' + result.Nom_Ca, textX - pdf.getTextWidth(nometprenom), textY, { align: 'right' });
      
            textY += 5; // Move down for the next line
            pdf.setFont('Sakkal Majalla'); // Set font
            const datenetlieu = " :تاريخ ومكان الميلاد";
            pdf.text(datenetlieu, textX, textY, { align: 'right' });
      
            pdf.setFont('Sakkal majallab');
            // Replace slashes with dashes in result.DateNais
            const formattedDate = result.DateNais.replace(/\//g, '-');
      
            // Now use the formatted date in the PDF
            pdf.text(formattedDate, textX - pdf.getTextWidth(datenetlieu), textY, { align: 'right' });
            pdf.text(result.LieuNais + ' ', textX - pdf.getTextWidth(datenetlieu) - pdf.getTextWidth(result.DateNais), textY, { align: 'right' });
      
      
            textY += 5;
            pdf.setFont('Sakkal Majalla'); // Set font
            const nomsp = " :الإختصاص";
            pdf.text(nomsp, textX, textY, { align: 'right' });
            pdf.setFont('Sakkal majallab');
            pdf.text(result.Nom, textX - pdf.getTextWidth(nomsp), textY, { align: 'right' });
      
      
            textY += 5;
            pdf.setFont('Sakkal Majalla'); // Set font
            const modefor = " :نمط التكوين";
            pdf.text(modefor, textX, textY, { align: 'right' });
            pdf.setFont('Sakkal majallab');
            pdf.text(result.Mode_formation_Nom, textX - pdf.getTextWidth(modefor), textY, { align: 'right' });
      
            pdf.setFont('Sakkal Majalla'); // Set font
            const regimtyp = "  :نظام";
            pdf.text(regimtyp, textX - pdf.getTextWidth(modefor) - pdf.getTextWidth(result.Mode_formation_Nom) - 3, textY, { align: 'right' });
            pdf.setFont('Sakkal majallab');
            pdf.text(result.apprenant_Regime_Nom, textX - pdf.getTextWidth(modefor) - pdf.getTextWidth(result.Mode_formation_Nom) - pdf.getTextWidth(regimtyp) - 1, textY, { align: 'right' });
      
            textY += 5;
            //pdf.text('فترة التكوين من: 10-12-2024 إلى 29-02-2026', textX, textY, { align: 'right' });
      
            // Convert result.DateDF and result.DateFF to Date objects
            var dateDF = new Date(result.DateDF);
            var dateFF = new Date(result.DateFF);
      
            // Get day, month, and year components
            var dayDF = dateDF.getDate();
            var monthDF = dateDF.getMonth() + 1; // Months are zero-based, so add 1
            var yearDF = dateDF.getFullYear();
      
            var dayFF = dateFF.getDate();
            var monthFF = dateFF.getMonth() + 1; // Months are zero-based, so add 1
            var yearFF = dateFF.getFullYear();
      
            // Format the date strings as dd-mm-yyyy
            //var formattedDateDF = ('0' + dayDF).slice(-2) + '-' + ('0' + monthDF).slice(-2) + '-' + yearDF;
            //var formattedDateFF = ('0' + dayFF).slice(-2) + '-' + ('0' + monthFF).slice(-2) + '-' + yearFF;
      
      
            var formattedDateDF = yearDF + '-' + ('0' + monthDF).slice(-2) + '-' + ('0' + dayDF).slice(-2);
            var formattedDateFF = yearFF + '-' + ('0' + monthFF).slice(-2) + '-' + ('0' + dayFF).slice(-2);
      
            pdf.setFont('Sakkal Majalla'); // Set font
            const modatakwin = " :مدة التكوين من";
            pdf.text(modatakwin, textX, textY, { align: 'right' });
            pdf.setFont('Sakkal majallab');
            pdf.text(formattedDateDF, textX - pdf.getTextWidth(modatakwin), textY, { align: 'right' });
      
            pdf.setFont('Sakkal Majalla'); // Set font
            pdf.text(' إلى ', textX - pdf.getTextWidth(modatakwin) - pdf.getTextWidth(formattedDateDF) - 1.2, textY, { align: 'right' });
      
            pdf.setFont('Sakkal majallab');
            pdf.text(formattedDateFF, textX - pdf.getTextWidth(modatakwin) - pdf.getTextWidth(formattedDateDF) - 5, textY, { align: 'right' });
      
            textY += 5;
            pdf.setFont('Sakkal Majalla'); // Set font
            const annform = " :السنة التكوينيـة";
      
            pdf.text(annform, textX, textY, { align: 'right' });
            pdf.setFont('Sakkal majallab');
            pdf.text(Annee_formation_Nom, textX - pdf.getTextWidth(annform), textY, { align: 'right' });
      */
      pdf.setFont('credit_card_regular');
      pdf.setFontSize(4);
      pdf.text(result.IDCandidat.toString(), textX - 49, textY + 3, { align: 'right' });

      // Display the formatted dates
      //pdf.text('مدة التكوين من: ' + formattedDateDF + ' إلى ' + formattedDateFF, pdf.internal.pageSize.getWidth() - 10, 160, { align: 'right' });

      // Draw training year
      //pdf.text('السنة التكوينيـة: ' + Annee_formation_Nom, pdf.internal.pageSize.getWidth() - 10, 170, { align: 'right' });


      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);


    }
  }, [result, pdfUrl, loadingauth]); // pdfUrl added to dependency array

  const classes_CircularProgress = useStyles_CircularProgress();


  useEffect(() => {


    if (!loadingauth && loadcrypted) {
      if (
        (IDetablissement === null ||
          IDDFEP === null ||
          IDNature_etsF === null) && (
          (download_cert_scol_active_state && Number(download_cert_scol_active_state) === 0)
          ||
          (download_cert_scol_active_dfep_state && Number(download_cert_scol_active_dfep_state) === 0)
          ||
          (download_cert_scol_active_mfep_state && Number(download_cert_scol_active_mfep_state) === 0)

        )

      ) {


        navigate('/');
        return null; // Return null to prevent further rendering




      }


    }
  }, [loadcrypted, IDetablissement, IDDFEP,
    loadingauth, download_cert_scol_active_state, download_cert_scol_active_dfep_state, download_cert_scol_active_mfep_state
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  return (
    <div style={{ width: '100%', height: '100%' }}>

      {!pdfUrl &&
        <div className={classes_CircularProgress.overlay}>

          <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress sx={{ ml: 1 }} color="primary" />
              <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                جاري التحميل...
              </Typography>
            </Box>
          </Dialog>

        </div>
      }


      {pdfUrl && (

        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="800px"
          aria-label="Generated PDF"
        >
        </object>


      )}
      {/* Render QR code component */}
      <div style={{ display: 'none' }}>
        <QRCode id="qrCode" value={`${window.location.origin}${window.location.pathname}`} />
      </div>
    </div>
  );
};

export default Cardmoutakawin;

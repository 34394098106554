import React, { useState, useEffect, useRef } from 'react';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import './MyToolbarStyles.css'; // Adjust the path as necessary
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Grid } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import { arSD } from '@mui/x-data-grid/locales';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import XLSX from 'sheetjs-style';
import useAuthentication_etab from "./useAuthentication_etab";

import { Typography, Box } from '@mui/material';
import Divider from '@mui/material/Divider';

const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  palette: {
    primary: {
      main: defaultColor
    },
    secondary: {
      main: defaultColor
    }
  },
  direction: 'rtl',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          "&:hover": {
            color: hoverColor,
          },
          color: defaultColor, // Default label color
        },
      },
    },
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  },
});
// Create a cache with RTL support
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});

function CustomToolbar({ columns, handleExportExcel }) {
  return (


    <GridToolbarContainer className="custom-toolbar">
      <GridToolbarColumnsButton className="custom-toolbar" />
      <GridToolbarFilterButton className="custom-toolbar" />
      <GridToolbarDensitySelector className="custom-toolbar" />
      <Button className="custom-toolbar" onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport className="custom-toolbar" />

      <ThemeProvider theme={themetextfieldnew}>
        <GridToolbarQuickFilter
          className="custom-toolbar"

        />
      </ThemeProvider>



    </GridToolbarContainer>
  );
}




const Orderninmoutakawin = () => {
  const {
    IDetablissement,
    IDEts_Form,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_etab();


  const [open, setOpen] = React.useState(false);
  const [fullDescription, setFullDescription] = React.useState('');
  const [nom, setNom] = React.useState('');

  const handleOpen = (description, nom) => {
    setFullDescription(description);
    setNom(nom);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [rows, setRows] = useState([]);
  const [loadingData, setLoadingData] = useState(true);


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );


  useEffect(() => {
    const apiUrl_mode_formation = `${API_BASE_URL}/api/getlistofallmode_formationinfo`;

    fetch(apiUrl_mode_formation, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setRows(data.data);
        } else {
          console.error("Unexpected data format:", data);
          setRows([]); // Set to empty array to avoid errors in DataGrid
        }
        setLoadingData(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoadingData(false);
      });
  }, []);


  const columns = [
    {
      field: 'IDMode_formation',
      headerName: 'رقم',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Nom', headerName: 'التسمية', width: 350,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },
    {
      field: 'NomFr', headerName: 'Nom (Fr)', width: 350,
      headerClassName: 'super-app-theme--header',
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <div className="ltr-direction">{params.value}</div>
      ),
    },
    {
      field: 'AgeMin',
      headerName: 'العمر الأدنى',
      width: 130,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.value === 0 ? '-' : params.value;
      }
    }

    ,
    {
      field: 'AgeMAx', headerName: 'العمر الأقصى', width: 130,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.value === 0 ? '-' : params.value;
      }
    },
    {
      field: 'Description',
      headerName: 'اقرأ المزيد',
      width: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Button
            sx={{
              backgroundColor: '#00695c', // Green color
              '&:hover': {
                backgroundColor: '#004d40', // Darker green on hover
              },
              minWidth: 'auto', // Adjust button width to fit content
              padding: '4px 8px', // Adjust padding if needed
            }}
            onClick={() => handleOpen(params.row.Description, params.row.Nom)} // Pass both Description and Nom
            variant="contained"
            endIcon={
              <ExpandMoreIcon
                sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }}
              />
            }
          >
            <Typography
              sx={{
                color: '#FFFFFF',
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              اقرأ المزيد
            </Typography>
          </Button>
        </div>
      ),
    }
  ];

  const handleRowDoubleClick = (params) => {
    console.log('Row double clicked:', params.row);
  };


  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDEts_Form={IDEts_Form}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

      <Box
        sx={{
          '& .super-app-theme--header': {
            fontFamily: 'Cairo',
            fontSize: '20px',
            fontWeight: 'bold', // Make the font bold
            backgroundColor: '#004d40', // Light green background color

            borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
            fontWeight: 900,
            color: '#FFFFFF'
          },
        }}


      >

        <Grid item sx={{ width: "100%" }}>

          <Divider sx={{ mt: '15px' }}>
            <Grid item  >
              <Typography
                style={{
                  color: "#004d40",
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 18,
                  fontWeight: 700,

                }}
                variant="h3" gutterBottom>

                <VideoLibraryIcon sx={{ mr: '5px', ml: '5px' }} />
                كيفية الحصول على رقم التعريف الوطني
              </Typography>
            </Grid>

          </Divider>
        </Grid>


        <Grid container>
          <Grid item sx={{ width: "100%" }}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={theme_datagrid}>


                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: "#004d40",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 18,
                    fontWeight: 700,
                    textAlign: "left", // Aligns text to the right for RTL
                    lineHeight: 1.5, // Improve readability
                    marginBottom: '16px', // Add space below the text
                    marginLeft:'16px',
                  }}
                >
                  للحصول على رقم التعريف الوطني الخاص بك، في حال لم تكن تملك بطاقة تعريف وطنية، يمكن لولي أمرك زيارة الرابط أدناه.
                  <br />
                  يجب عليه ملء القسم العلوي بالمعلومات الخاصة به، والقسم السفلي بالمعلومات الخاصة بك.
                  <br />
                  يمكنك مشاهدة الفيديو التوضيحي للحصول على مزيد من التفاصيل حول الخطوات المطلوبة.
                </Typography>





                {/* Link to the relevant site */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center the link horizontally
                    alignItems: "center",     // Center the link vertically
                    marginTop: "8px",
                  }}
                >
                  <a
                    href="https://etatcivil.interieur.gov.dz/ActeNaissance"
                    style={{
                      color: "#004d40",
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                      padding: "5px 5px", // Added padding for better clickability
                      border: "1px solid #004d40", // Optional: border to enhance visibility
                      borderRadius: "4px", // Optional: rounded corners for the button effect
                      transition: "background-color 0.3s, color 0.3s", // Transition for hover effect
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#004d40"; // Hover background
                      e.currentTarget.style.color = "#fff"; // Hover text color
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background
                      e.currentTarget.style.color = "#004d40"; // Reset text color
                    }}
                  >
                    https://etatcivil.interieur.gov.dz/ActeNaissance
                  </a>
                </div>

                {/* Video container */}
               
                  <video
                    controls
                    style={{
                      marginTop:'5px',
                      maxWidth: '100%',  // Ensures the video fits within its container
                      maxHeight: '100%', // Ensures the video doesn't exceed container height
                      objectFit: 'contain', // Maintain aspect ratio
                      
                    }}
                    src="/videos/orderninmoutakawin.mp4"
                    type="video/mp4"
                  >
                    Your browser does not support the video tag.
                  </video>
                 
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        </Grid>



      </Box>

      <Footer />
    </>
  );
};

export default Orderninmoutakawin;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Link, Grid, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GroupIcon from '@mui/icons-material/Group'; // Import the Group icon

export default function Footer() {
    const [visitorCount, setVisitorCount] = useState(0);
    const [error, setError] = useState(null);
    const [isFooterFixed, setIsFooterFixed] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const fetchVisitorCount = async () => {
            try {
                const response = await axios.get(`https://tachbik4.mfp.gov.dz/api/countVisitors`, {
                    withCredentials: true,
                });

                if (response.data && typeof response.data.unique_visitors === 'number') {
                    setVisitorCount(response.data.unique_visitors);
                }
            } catch (error) {
                setError('Error fetching visitor count'); // Set an error message
            }
        };

        fetchVisitorCount();

        // Check if footer should be fixed based on document height
        const checkFooterPosition = () => {
            setIsFooterFixed(window.innerHeight >= document.documentElement.scrollHeight);
        };

        // Initial check
        checkFooterPosition();

        // Add event listener for window resize
        window.addEventListener('resize', checkFooterPosition);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', checkFooterPosition);
        };
    }, []); // Run once on mount

    return (
        <Container maxWidth={false} disableGutters sx={{ mt: '100px' }}>
            <Box
                component="footer"
                sx={{
                    position: isFooterFixed ? 'fixed' : 'relative',
                    bottom: 0,
                    width: '100%',
                    py: 2,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#004d40',
                    zIndex: 1,
                }}
            >
                <Grid container justifyContent="center" sx={{ mb: 2 }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <GroupIcon sx={{ marginRight: 1, marginLeft: 1, color: '#ffffff' }} />
                        <Typography
                            variant="h6"
                            sx={{
                                fontFamily: '"Tajawal", sans-serif',
                                color: "#ffffff",
                                fontSize: 18,
                                fontWeight: 'bold',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            عدد الزوار حاليا:
                            <span style={{ color: '#ffd700', marginRight: '4px', fontWeight: 'normal' }}>
                                {visitorCount}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item sx={{ mt: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                            <Link
                                href="https://www.mfep.gov.dz/"
                                underline="none"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    color: "#ffffff",
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    transition: 'color 0.3s',
                                    '&:hover': {
                                        color: '#d1e0e0', // Change link color on hover
                                    },
                                }}
                            >
                                وزارة التكوين والتعليم المهنيين {'© '}
                                {new Date().getFullYear()}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
                {error && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </Container>
    );
}
